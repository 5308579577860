/* eslint-disable */
import React, { useState } from "react";

import { Fade, Reveal } from "react-awesome-reveal";
import {
  fadeInDownShorter,
  fadeInLeft,
  fadeInRight,
  fadeInUp,
} from "./animation";

import { mint_guide } from "../prime";

function Guide() {
  return (
    <div className="mt-5 flex flex-col lg:flex-row justify-evenly items-center mb-5">
      {mint_guide.map((item, i) => (
        <Reveal
          keyframes={fadeInDownShorter}
          duration={800}
          delay={250 * (i + 1)}>
          <div className="max-w-md bg-[#ffffff1a] hover:bg-[#ffffff2f] transition duration-300 m-3 lg:m-0 ease-in-out space-y-7 w-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="flex items-center justify-center ">
              <img
                className=" p-2 w-100 h-48 lg:h-72 cover-fit rounded-2xl "
                src={item.img}
              />
            </div>
            <div className="p-5">
              <a>
                <h5 className="mb-6 px-2 text-xl lg:text-2xl font-bold tracking-tight text-white dark:text-white">
                  {item.header}
                </h5>
              </a>

              <p className="mb-6 text-base lg:text-lg h-20 px-2 font-normal opacity-70 dark:text-gray-400">
                {item.text}
              </p>

              <Reveal
                keyframes={fadeInDownShorter}
                duration={800}
                delay={250 * (i + 1)}>
                <a href="https://medium.com/@kaafila" class="mr-9 text-neutral-800 dark:text-neutral-200">
            <svg class="h-8 w-8 text-black-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
</svg>
                
          
                </a>
              </Reveal>
            </div>
          </div>
        </Reveal>
      ))}
    </div>
  );
}

export default Guide;
