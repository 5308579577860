/* eslint-disable */
import React, { useState } from "react";

import { Fade, Reveal } from "react-awesome-reveal";
import {
  fadeInDownShorter,
  fadeInLeft,
  fadeInRight,
  fadeInUp,
} from "./animation";

import { team } from "../prime";

function Team() {
  return (
    <div>
      <div className="shrink w-120 flex-col items-center justify-center mt-5">
        <div className="flex flex-col items-center justify-center">
          <Reveal keyframes={fadeInDownShorter} duration={1000} delay={500}>
            <h1 className="text-3xl lg:text-6xl font-bold tracking-tight text-center lg:text-center mt-10 lg:mt-40">
              Team Members
            </h1>
          </Reveal>
          </div>
         

      
        <div className="mt-5 flex flex-col lg:flex-row justify-center space-x lg:space-x-10 items-center mb-5 py-16  ">
          {team.map((item, i) => (
            <Reveal
              keyframes={fadeInDownShorter}
              duration={1000}
              delay={300 * (i + 1)}>
              <div className="max-w-md bg-[#ffffff1a] hover:bg-[#ffffff29] transition duration-300 m-3 lg:m-0 ease-in-out pace-y-7 w-100 rounded-2xl shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="flex items-center justify-center ">
                  <img
                    className="p-2 w-100 h-48 lg:h-72 cover-fit rounded-2xl "
                    src={item.img}
                  />
                </div>

                <div className="p-5 tracking-wide">
                  <div>
                    <h5 className="text-center mb-6 px-2  text-xl lg:text-3xl font-bold tracking-tight text-white dark:text-white">
                      {item.name}
                    </h5>
                  </div>
                  <p className=" text-sm lg:text-lg mb-6 text-center px-2 font-normal opacity-70 hover:opacity-80 dark:text-gray-400">
                    {item.job}
                  </p>
                  
              
                </div>
              </div>
            </Reveal>
          ))}
        </div>
      </div>

     
           
    </div>
  );
}

export default Team;
