export const header_data = {
  intro: "A Decentralised Media and Education Platform",
 };

export const mint_guide = [
  {
    id: 0,
    header: "Kaafila Metaverse presentation to Polygon DAO",
    text: "Kaafila Metaverse made a presentation to Polygon DAO on 24/08/2022, which was attended by 651 people, mostly from Polygon Community.",
    img: "https://www.moonstats.com/news/wp-content/uploads/2021/07/Polygon-MATIC.png",
  },
  {
    id: 1,
    header: "Kaafila Metaverse Overview",
    text: "Kaafila Metaverse is a virtual world with Virtual Classrooms, Virtual Offices, Research Labs, Industry Parks, Industry Events, and Virtual Travel.",
    img: "https://tse3.mm.bing.net/th?id=OIP.aj6HNooEVzmTty7GFZcsCgHaHa&pid=Api&P=0",
  },
  {
    id: 3,
    header:
      "Kaafila among Highest Ranked Projects in Algorand Blockchain Ecosystem",
    text: "We are happy to share this update! Kaafila is now among the Highest Ranked Projects in the Algorand Blockchain Ecosystem",
    img: "https://tse4.mm.bing.net/th?id=OIP.-AkjIc6FV1TxjjtjZoZD1QHaEL&pid=Api&P=0",
  },
];

export const about = {
  title: "Tokenomics",
  content1:
  "KFL tokens will be released on a “4-year halving schedule”, to ensure enough supply of tokens to reward future efforts to maintain and grow the Kaafila project. This gradual token release schedule has been adopted from Bitcoin, which has demonstrated clear benefits of limited and controlled supply of tokens over a long period of time.",
  content2:
  "KFL token has predefined maximum supply of 100 billion tokens, and the tokens will be released gradually every year, over the next 20+ years, based on the combined efforts of the Kaafila project Team and Users.",
  content3:
  "Some of the released tokens may then become available to Investors for buying through exchange/swap sites, when Users or Team Members put their tokens for sale. The participation of Investors will help liquidity and price stability for tokens, and benefit all stakeholders of the project.",

};


export const team = [
  {
    name: "Shankar AVSB",
    job: "Founder",
    id: 0,
    img: "https://media.licdn.com/dms/image/C5603AQHmQ0w0Fo_BtQ/profile-displayphoto-shrink_400_400/0/1544957727845?e=1689206400&v=beta&t=WNgd9iZO4aASot1GD_L0DqKKLK2y7kaRushxtxKU0oY",
  },
  {
    name: "Ethan William",
    job: "Founder",
    id: 0,
    img: "https://media.licdn.com/dms/image/D4D03AQF7OoBOXCPLgw/profile-displayphoto-shrink_100_100/0/1667311563819?e=1689206400&v=beta&t=CN857PZvUiGQoQwIEKntSXXQiQuD3thwcz3PDCAazdA",
  },
  {
    name: "Marc Vergara",
    job: "Lead Blockchain Developer",
    id: 0,
    img: "https://media.licdn.com/dms/image/C4D03AQFRBU6_Z8f9Kw/profile-displayphoto-shrink_100_100/0/1622528967678?e=1689206400&v=beta&t=bJD4bTvX4SvoPT57wlXQG0kLnJZ8A4KVIqQqZzh1N4w",
  },
  {
    name: "Dharani S",
    job: "Project Manager",
    id: 0,
    img: "https://media.licdn.com/dms/image/C4D03AQEygYtyR5VAwQ/profile-displayphoto-shrink_400_400/0/1657196741893?e=1689206400&v=beta&t=PUxYjla2FOT8SQtRBcMjqvepvWCFOZYduiPv8WucApY",
  },
  {
    name: "Maria",
    job: "UI?UX Designer",
    id: 0,
    img: " ",
  },
 
];






